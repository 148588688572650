<template>
  <div class="profile">
    <ProfileForm />
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import ProfileForm from '@/components/ProfileForm.vue'; // @ is an alias to /src
@Component({
  components: {
    ProfileForm
  }
})
export default class Profile extends Vue {}
</script>
