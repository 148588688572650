<template>
  <v-bottom-sheet hide-overlay persistent :value="showSheet">
    <v-card-actions style="background-color:white;">
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('handleSave')" :disabled="disabled">Save</v-btn>
    </v-card-actions>
  </v-bottom-sheet>
</template>

<script>
export default {
  name: 'BottomSheet',
  data: () => ({
    showSheet: false
  }),
  props: ['disabled', 'currentTab', 'tab'],
  watch: {
    currentTab: {
      handler(newValue) {
        if (newValue === this.tab) this.showSheet = true;
        else this.showSheet = false;
      },
      immediate: true
    }
  }
};
</script>

<style></style>
