<template>
  <v-container>
    <v-layout style="height: 100vw;margin-top:100px" column>
      <v-flex style="overflow:auto">
        <v-form ref="profileForm" v-model="valid">
          <v-layout row>
            <v-flex xs12 md5>
              <v-text-field
                prepend-icon="person"
                name="username"
                label="Username"
                type="text"
                v-model="userName"
                disabled="disabled"
                :rules="userNameRules"
              />
            </v-flex>
          </v-layout>
          <v-layout row>
            <v-flex xs12 md5>
              <v-text-field
                id="password"
                prepend-icon="lock"
                name="password"
                label="Password"
                type="password"
                v-model="password"
              />
            </v-flex>
          </v-layout>
        </v-form>
      </v-flex>
    </v-layout>
    <BottomSheet @handleSave="handleSave" />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import BottomSheet from '@/components/Common/BottomSheet';
import { getUserProfile } from '@/api/UserProfile/getUserProfile';
import { postUserProfile } from '@/api/UserProfile/postUserProfile';
export default {
  name: 'ProfileForm',
  data: () => ({
    valid: true,
    userName: '',
    password: '',

    userNameRules: [(v) => !!v || 'Password is required']
  }),
  components: {
    BottomSheet
  },
  async mounted() {
    const data = await getUserProfile(this.userProfile.accessToken, process.env.VUE_APP_API_URL);
    if (data && data.response) {
      const { email } = data.response;
      this.userName = email;
    }
  },
  computed: {
    ...mapState({
      userProfile: (state) => state.userProfile.userProfile
    }),
    formTitle() {
      return this.$route.name.includes('Create') ? 'Create Profile' : 'Edit Profile';
    }
  },
  methods: {
    async handleSave() {
      if (this.$refs.profileForm.validate()) {
        const requestBody = { email: this.userName, password: this.password };
        const data = await postUserProfile(requestBody, this.userProfile.accessToken, process.env.VUE_APP_API_URL);
        if (data && data.response) {
          this.$router.go(-1);
        }
      }
    }
  }
};
</script>
